<template>
  <div class="container">
    <h1 class="page-title">Singolo modulo</h1>
    <div v-if="course">
      {{course.attributes.title}}
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return{
      course: null
    }
  },
  computed: {
    courseID() {
      return this.$route.params.id;
    },
  },
  methods:{
    fetchModule(){
      fetch(`${process.env.VUE_APP_ENDPOINT}/jsonapi/node/course/${this.courseID}`)
        .then(res=>res.json())
        .then(json=>{
          this.course = json.data
        })
    }
  },
  mounted(){
    this.fetchModule()
  }
}
</script>